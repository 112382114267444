import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 800.000000 800.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)">


<path d="M3142 5924 c-18 -20 -30 -40 -26 -45 3 -5 -2 -6 -11 -3 -11 5 -16 2
-14 -7 6 -38 -58 -113 -87 -102 -12 4 -15 3 -10 -5 4 -7 16 -12 27 -12 24 0
24 -11 -1 -43 -13 -16 -27 -24 -36 -20 -8 3 -13 8 -10 10 3 3 -1 11 -7 18 -9
10 -16 7 -34 -14 -31 -35 -29 -49 1 -16 23 24 25 25 23 7 0 -12 -5 -18 -9 -16
-4 3 -8 -2 -8 -10 0 -9 -7 -16 -15 -16 -8 0 -15 -4 -15 -10 0 -13 13 -13 27 1
9 9 19 6 39 -10 15 -11 24 -22 20 -22 -3 -1 -12 -2 -18 -3 -10 -1 -14 -43 -16
-167 -2 -164 -2 -166 20 -177 13 -6 17 -11 11 -11 -7 -1 -13 -8 -13 -16 0 -8
-4 -15 -10 -15 -5 0 -10 -7 -10 -16 0 -8 4 -13 9 -10 14 9 42 -5 34 -16 -3 -6
8 -3 25 7 17 10 29 22 26 27 -3 4 3 8 13 8 10 0 44 18 75 40 l58 40 32 -20
c37 -22 53 -26 42 -8 -5 7 -3 8 5 4 7 -5 10 -11 7 -14 -9 -9 3 -16 269 -157
33 -18 87 -47 120 -65 33 -18 128 -68 210 -113 83 -44 206 -111 274 -149 68
-38 125 -67 127 -66 2 2 -12 19 -29 38 -18 19 -68 76 -112 125 -43 50 -95 108
-115 130 -89 98 -238 264 -274 305 -22 25 -81 90 -131 145 -51 55 -112 123
-136 151 -115 133 -215 239 -226 238 -6 -1 -10 4 -7 10 2 6 -16 32 -40 58
l-43 47 -31 -35z m-2 -579 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6
-2 -15z m-78 -90 c16 -20 16 -21 -1 -21 -13 0 -18 8 -18 24 0 12 0 22 1 20 1
-2 9 -12 18 -23z m78 21 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16
-4z m-40 -16 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17
-5 25 -10z"/>
<path d="M3056 5831 c-23 -24 -25 -29 -10 -25 11 3 24 16 31 30 14 31 13 30
-21 -5z"/>
<path d="M3010 5780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2977 5743 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2993 5725 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"/>
<path d="M2854 5618 c-10 -12 -13 -18 -5 -13 8 4 16 2 21 -5 5 -8 8 -3 7 13
-1 15 -2 27 -3 27 0 0 -9 -10 -20 -22z"/>
<path d="M2939 5614 c-12 -14 -11 -16 5 -13 11 2 21 10 24 17 5 17 -13 15 -29
-4z"/>
<path d="M2740 5489 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2626 5373 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2939 5191 c11 -7 10 -10 -4 -18 -12 -7 -15 -15 -9 -26 7 -11 14 -13
24 -7 11 7 11 10 2 10 -7 0 -10 5 -7 10 5 8 11 8 20 0 9 -8 15 -8 20 0 3 5 0
10 -7 10 -10 0 -9 3 2 11 13 9 12 10 -2 5 -10 -3 -20 -1 -23 4 -4 6 -12 10
-18 10 -8 0 -7 -3 2 -9z"/>
<path d="M2884 5104 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
<path d="M2845 5080 c-4 -7 -16 -10 -26 -7 -11 3 -19 1 -19 -4 0 -5 8 -9 18
-9 22 0 48 17 40 25 -3 4 -9 1 -13 -5z"/>
<path d="M3738 4829 c-10 -7 -10 -9 2 -10 10 0 8 -3 -5 -9 -14 -6 -15 -9 -4
-9 8 -1 21 5 28 12 10 10 11 9 6 -5 -4 -10 -3 -18 3 -18 6 0 12 6 15 13 2 6 8
9 12 5 11 -10 -5 -48 -21 -48 -8 0 -14 -4 -14 -10 0 -5 9 -10 20 -10 11 0 20
-4 20 -10 0 -5 -7 -10 -16 -10 -29 0 -3 -20 29 -21 l32 -2 -35 -6 -35 -7 50
-11 c28 -6 131 -27 229 -47 99 -21 185 -40 190 -43 8 -4 8 -2 1 5 -20 21 -401
206 -419 203 -10 -1 -16 1 -13 6 8 14 -59 42 -75 32z"/>
<path d="M2825 4250 c-11 -5 -15 -9 -8 -9 6 -1 10 -12 9 -26 -2 -13 1 -22 6
-19 9 6 7 -30 -8 -123 -3 -18 -1 -33 5 -33 12 0 11 -59 -2 -67 -5 -3 -13 0
-17 7 -8 13 -8 8 -14 -135 l-4 -90 7 85 c7 94 27 136 68 146 24 6 25 10 21 60
l-5 54 116 0 117 0 57 74 c31 41 57 77 57 80 0 9 -383 5 -405 -4z"/>
<path d="M3648 4253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3700 4250 c13 -8 13 -10 -1 -10 -13 0 -18 -11 -21 -45 -4 -60 -2
-65 35 -66 l32 -1 -30 -8 c-30 -7 -30 -7 13 -15 53 -10 101 -40 127 -82 11
-18 27 -33 35 -33 10 -1 11 -3 3 -7 -19 -7 -16 -50 4 -61 13 -8 17 -29 21 -98
4 -94 12 -114 34 -94 9 8 13 36 12 84 l-1 71 4 -67 c2 -38 8 -68 13 -68 16 0
67 94 79 147 38 156 -76 311 -259 352 -60 14 -120 14 -100 1z m15 -100 c13 -6
15 -9 5 -9 -8 0 -22 4 -30 9 -18 12 -2 12 25 0z m229 -196 c5 -14 4 -15 -9 -4
-17 14 -19 20 -6 20 5 0 12 -7 15 -16z m24 -49 c-3 -3 -9 2 -12 12 -6 14 -5
15 5 6 7 -7 10 -15 7 -18z"/>
<path d="M4498 4253 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M4702 4240 c31 -24 52 -72 46 -105 -5 -23 -2 -25 32 -25 38 0 41 3
143 138 7 9 -18 12 -118 12 l-128 0 25 -20z"/>
<path d="M4510 4229 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4594 4225 c-33 -14 -62 -71 -50 -100 10 -27 22 -15 22 21 0 93 121
83 138 -10 8 -42 26 -39 26 4 0 35 -43 86 -80 93 -14 3 -39 0 -56 -8z"/>
<path d="M4612 4168 c-17 -17 -15 -58 3 -58 8 0 15 7 15 15 0 8 5 15 10 15 6
0 10 -7 10 -15 0 -8 7 -15 15 -15 21 0 19 33 -3 53 -22 20 -34 21 -50 5z"/>
<path d="M4492 4145 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4499 3999 c19 -8 23 -15 19 -44 -2 -19 0 -35 4 -35 9 0 12 22 9 58
-2 20 0 22 12 11 16 -15 17 -40 2 -51 -6 -4 -2 -8 8 -8 9 0 17 -5 17 -11 0 -7
-21 -10 -60 -7 -33 1 -60 0 -60 -4 0 -3 29 -8 65 -10 l65 -3 0 30 c0 54 10 75
36 75 27 0 32 -8 36 -65 4 -37 6 -40 36 -43 27 -3 32 0 38 25 9 34 51 73 79
73 15 -1 11 -5 -12 -15 -20 -9 -37 -26 -46 -47 -12 -30 -12 -34 7 -43 17 -9
22 -8 27 4 4 9 6 -5 5 -31 0 -25 -3 -46 -6 -45 -14 4 -50 -26 -50 -42 0 -24
25 -45 42 -35 10 5 10 4 2 -6 -21 -21 -54 -2 -54 31 0 26 -3 29 -33 29 -28 0
-33 -4 -35 -27 -3 -35 -14 -46 -43 -41 -19 2 -25 10 -27 36 -2 17 -7 32 -12
32 -5 0 -7 -12 -5 -27 l4 -28 -13 29 c-10 22 -14 25 -19 12 -4 -9 -3 -16 1
-16 4 0 7 -17 7 -38 0 -20 3 -45 7 -54 6 -12 3 -18 -10 -21 -9 -3 37 -5 104
-6 186 -2 264 30 300 124 25 67 14 137 -28 178 -51 49 -90 61 -222 65 -90 2
-121 0 -128 -10 -8 -10 -11 -10 -15 0 -2 6 -21 12 -41 11 -34 0 -35 -1 -13
-10z m344 -76 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m39
-130 c2 -33 0 -59 -4 -56 -5 2 -7 41 -7 86 2 86 6 74 11 -30z m-92 -5 c0 -7
-4 -19 -9 -27 -7 -10 -9 -7 -8 12 2 26 17 39 17 15z"/>
<path d="M4604 3945 c-6 -36 1 -58 18 -53 17 6 18 76 2 82 -9 3 -15 -6 -20
-29z"/>
<path d="M2850 3943 c-16 -20 -21 -38 -18 -65 2 -21 0 -38 -4 -38 -11 0 -10
-72 2 -90 20 -30 31 11 27 92 -5 69 -3 77 14 81 11 3 19 14 19 26 0 29 -14 26
-40 -6z"/>
<path d="M4480 3962 c0 -7 -10 -13 -22 -13 -19 -1 -20 -2 -4 -6 20 -5 44 12
33 24 -4 3 -7 1 -7 -5z"/>
<path d="M3853 3871 c-14 -28 -23 -67 -23 -95 0 -34 -4 -48 -16 -53 -27 -10
-49 11 -49 46 0 27 -3 31 -20 27 -11 -3 -28 -6 -37 -6 -14 0 -18 -8 -18 -35 0
-29 -4 -36 -25 -41 -14 -3 -22 -10 -20 -15 3 -4 12 -6 20 -2 9 3 15 -1 15 -10
0 -9 8 -18 18 -20 9 -3 -1 -4 -23 -2 -22 1 -31 0 -20 -2 11 -3 23 -9 26 -15 9
-15 142 7 197 33 59 28 57 26 45 41 -6 7 -13 50 -16 95 -7 119 -19 130 -54 54z
m-123 -95 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z"/>
<path d="M2880 3823 c0 -88 -4 -97 -42 -107 -16 -3 -28 -11 -28 -17 0 -6 4 -8
9 -5 5 4 12 0 14 -6 4 -10 8 -10 14 -2 6 9 11 8 20 -2 10 -10 5 -14 -30 -17
l-42 -4 45 -3 c40 -3 38 -4 -25 -10 l-70 -6 73 -2 72 -2 0 130 c0 72 -2 130
-5 130 -3 0 -5 -35 -5 -77z"/>
<path d="M3798 3819 c-23 -13 -25 -79 -3 -79 8 0 15 1 16 3 3 13 9 87 7 87 -2
0 -11 -5 -20 -11z"/>
<path d="M4600 3765 c0 -18 5 -25 20 -25 15 0 19 6 18 25 -2 17 -8 25 -20 25
-13 0 -18 -8 -18 -25z"/>
<path d="M3644 3765 c4 -24 16 -30 16 -7 0 12 -4 22 -9 22 -5 0 -8 -7 -7 -15z"/>
<path d="M4069 3380 c-97 -20 -185 -40 -195 -44 -10 -4 -35 -10 -54 -12 -46
-6 -51 -14 -9 -15 33 -1 33 -1 -11 -13 -37 -10 -41 -13 -23 -18 19 -5 23 -12
21 -47 -1 -37 -11 -54 -20 -32 -1 4 -12 5 -23 0 -19 -7 -19 -8 -1 -8 15 -1 16
-4 6 -16 -8 -9 12 -3 46 14 32 16 142 71 244 121 151 75 229 117 200 109 -3
-1 -84 -18 -181 -39z"/>
<path d="M3748 3303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4205 3248 c-38 -22 -160 -88 -270 -147 -110 -60 -229 -124 -265
-143 -72 -40 -167 -91 -280 -151 -88 -47 -116 -65 -112 -73 2 -3 0 -9 -4 -12
-4 -4 -4 -1 0 6 10 17 -4 15 -41 -8 l-33 -20 -57 40 c-55 39 -93 51 -93 29 0
-6 7 -8 18 -4 14 5 15 4 5 -6 -21 -21 -40 5 -22 27 9 12 10 15 2 10 -7 -4 -13
-3 -13 3 0 6 -10 15 -21 22 -12 6 -19 7 -15 1 3 -5 -1 -12 -9 -16 -12 -4 -14
-43 -14 -242 l0 -236 25 -22 c15 -13 26 -35 29 -58 3 -32 2 -36 -9 -25 -8 6
-12 15 -9 20 5 8 -11 10 -20 1 -8 -8 73 -94 86 -91 6 1 11 -7 12 -18 0 -11 10
-24 23 -30 12 -5 22 -14 22 -19 0 -5 7 -18 16 -28 16 -17 17 -17 33 1 25 29
159 178 216 241 28 30 88 98 135 150 47 52 101 113 121 135 20 22 103 114 184
205 81 91 182 203 224 250 42 46 94 105 116 130 22 25 42 47 45 50 37 38 62
70 54 69 -5 -1 -40 -19 -79 -41z m-1096 -502 c-2 -2 -15 -9 -29 -15 -24 -11
-24 -11 -6 3 16 13 49 24 35 12z m25 -14 c2 -4 -5 -9 -17 -9 -17 -2 -19 0 -7
7 18 12 18 12 24 2z m-20 -531 c-5 -6 -34 20 -34 30 0 5 8 1 18 -8 10 -10 17
-20 16 -22z m-35 -25 c0 -7 -2 -6 -6 2 -2 6 -11 12 -19 12 -9 0 -12 5 -9 10 8
13 35 -6 34 -24z"/>
<path d="M2832 2929 c9 -5 15 -16 12 -23 -3 -7 2 -16 12 -20 14 -5 15 -4 4 9
-15 19 -1 19 23 1 14 -11 15 -15 5 -20 -7 -3 -3 -3 8 -1 24 6 41 -19 22 -31
-7 -5 -2 -11 13 -16 19 -7 21 -11 9 -19 -11 -7 -7 -8 14 -5 16 3 31 8 35 12 3
4 -3 4 -14 1 -20 -7 -20 -7 -1 8 20 15 16 19 -18 15 -10 -1 -16 2 -13 7 3 5 3
13 -1 19 -9 13 -106 74 -118 74 -5 0 -1 -5 8 -11z"/>
<path d="M2626 2627 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2785 2549 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2720 2526 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2760 2486 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2852 2402 c-9 -6 -5 -15 14 -33 l27 -24 -17 26 c-9 14 -13 29 -9 32
9 9 1 8 -15 -1z"/>
<path d="M2930 2396 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>
<path d="M2907 2360 c-5 -18 11 -49 40 -78 7 -8 13 -9 13 -3 0 12 32 2 42 -13
10 -16 19 -1 12 18 -5 13 -15 17 -35 14 -29 -5 -40 6 -16 15 9 4 9 6 0 6 -7 1
-10 7 -8 14 2 6 -6 20 -19 29 -23 17 -24 16 -29 -2z m36 -30 c3 -11 1 -20 -4
-20 -4 0 -13 9 -19 20 -8 16 -8 20 3 20 8 0 17 -9 20 -20z"/>
<path d="M2975 2260 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
